@import './var.scss';

.banner{
	&-wrap{
		width: 100%;
		height: 862px;
		img{
			width: 100%;
			height: 100%;
		}
	}
}

.banner:hover .swiper-button-next, .banner:hover .swiper-button-prev{
	display: block;
	color: #fff!important;
}


.main{
	&-comlist{
		padding: 60px 0;
		ul{
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		li{
			width: 25%;
			margin-right: 2%;
			margin-bottom: 30px;
		}
	}
	&-cover{
		position: relative;
		height: 410px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	&-tip{
		font-size: 18px;
		color: #333;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 500px;
	}
	&-tip:hover{
		color: #FF6600;
	}
	&-mask{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255,102,0,0.75);
		&-content{
			padding: 40px 60px;
			color: #fff;
		}
		&-title{
			width: 100%;
			height: 40px;
			line-height: 40px;
			font-size: 24px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			margin-bottom: 10px;
		}
		&-message{
			font-size: 14px;
			line-height: 20px;
		}
	}
	&-title{
		// background-color: #f60;
		color: #fff;
		padding: 20px 0 30px;
		p{		
			text-align: center;
			font-size: 46px;
			color: #333;
			padding: 10px 0;
			display: block;
		}
		&-text{
			text-align: center;
			font-size: 22px;
			margin-bottom: 10px;
			color: #7c7c7c;
		}
	}
	
	&-info{
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		&-list{
			height: 335px;
			background-color: #e9e9ea;
			position: relative;
			.banner{
				height: 335px;
			}
		}
		&-introduce{
			position: relative;
			h2{
				font-size: 28px;
				height: 48px;
			}
		}
		&-textwarp{
			position: relative;
			padding: 40px;
			color: #fff;
		}
		&-textarea{
			margin: 30px 30px;
			position: relative;
			padding: 60px 40px 20px;
			color: #fff;
		}
		&-text{
			max-height: 12em;
			line-height: 2;
			margin-top: 10px;
			font-size: 14px;
			margin-bottom: 4%;
		}
		&-icon{
			display: block;
			position: absolute;
			right: 30px;
			bottom: 30px;
			width: 46px;
			height: 46px;
			line-height: 46px;
			text-align: center;
			border: #fff 1px solid;
			cursor: pointer;
			color: #fff;
			i{
				-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
		}
	}
	
	&-new{
		height: 555px;
		margin-bottom: 30px;
		background: url(../images/pic.jpg) 0 0 no-repeat;
		background-size: 100% 100%;
		&-title{
			text-align: center;
			padding: 30px 0;
			font-size: 40px;
			color: #333;
		}
	}
	
	&-company{
		&-center{
			display: flex;
			margin-top: 20px;
			margin-left: 5%;
			margin-right: 5%;
			background-color: #fff;
		}
		&-message{
			padding: 10px 10% 40px;
		}
		&-list{
			display: block;
			width: 33.333%;
			border-right: 1px solid #b9b9b9;
			padding: 10px 0 40px;
		}
		&-list:hover h2{
			color: #FF6600;
		}
		
		&-title{
			display: inline-block;
			border-bottom: #fac9a7 2px solid;
			height: 30px;
			line-height: 30px;
			color: #878787;
			margin-bottom: 40px;
		}
		&-content{
			height: 100px;
			margin-bottom: 20px;
			img{
				display: block;
				width: 33%;
				height: 100%;
				float: left;
				margin-right: 3%;
			}
			h2{
				color: #333;
				font-size: 16px;
				font-weight: normal;
				line-height: 1.5em;
				margin-bottom: 30px;
				overflow: hidden;
				display: -webkit-box;
						 -webkit-box-orient: vertical;
						 -webkit-line-clamp: 2;
			}
			
		}
		&-text{
			color: #878787;
			overflow: hidden;
			width: 100%;
			display: -webkit-box;
			         -webkit-box-orient: vertical;
			         -webkit-line-clamp: 2;
		}
	}
}
.main-comlist:hover .main-mask{
	display: block;
}

.main-nav{
	display: flex;
	justify-content: center;
	background-color: #fff;
	ul{
		display: flex;
		li{
			font-size: 15px;
			position: relative;
			a{
				display: inline-block;
				padding: 10px 35px;
				line-height: 25px;
				color: #666;
				border-bottom: 1px solid rgba(0,0,0,0);
			}
			a:hover{
				color: #f27823;
			}
			
		}
		
		li:after {
		    content: '';
		    display: block;
		    width: 1px;
		    height: 16px;
		    background-color: #e3e3e3;
		    position: absolute;
		    left: 0;
		    top: 50%;
		    margin-top: -8px;
		}
	}
	
}

.main-nav ul li:first-child::after{
	width: 0px;
}


.main-nav a:hover:after, .main-nav .cur {
    width: 60%;
    left: 20%;
	color: #f27823 !important;
}

.main-nav ul li a:after{
	content: '';
	display: block;
	width: 0;
	height: 1px;
	background-color: #f27823;
	overflow: hidden;
	position: absolute;
	left: 50%;
	bottom: -1px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.main-nav .cur{
	position: absolute;
	height: 1px;
	background-color: #f27823;
}




.main-info-introduce:hover .main-info-icon{
	background-color: #fff;
	color: #FF6600;
}

